import request from '@/utils/request';

export function check(data) {
  return request({
    url: '/api/guaratee/verification/check',
    method: 'post',
    data,
  });
}

export function insureVerification(params) {
  return request({
    url: '/api/external/yanCheng/insureVerification',
    method: 'get',
    params,
  });
}


export default { insureVerification, check };
